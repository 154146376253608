@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --primary: #5683ff;
}

html {
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

h3 {
    @apply text-lg mb-2;
}

img {
    @apply rounded;
}

th,
tr,
td {
    @apply border border-black border-separate p-2;
}



p {
    @apply leading-8;
}

header .active {
    @apply text-[color:var(--primary)] underline-offset-8 underline;
}

.btn-primary {
    @apply bg-[color:var(--primary)] hover:bg-blue-900 text-white px-4 py-2 rounded border border-[color:var(--primary)] hover:border-blue-900;
}

.btn-secondary {
    @apply bg-gray-950/25 hover:bg-gray-950 text-white px-4 py-2 rounded border;
}

.container {
    @apply m-auto;
}